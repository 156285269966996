@import url('https://fonts.googleapis.com/css?family=Mukta+Vaani:200,300,400,500,600,700,800&subset=latin-ext');

html {
	position: relative;
	min-height: 100%;
}

body {
	min-height: 100%;
	font-family: 'Mukta Vaani', sans-serif;

	ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	p {
		font-size: 15px;
	}

	.btn-default {
		border-radius: 0;
		border: 1px solid #222;
		text-transform: uppercase;
		padding: 6px 25px;
		transition: all 0.3s;

		&:hover {
			background: #222;
			border-color: #222;
			color: #fff;
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

.news-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	article {
		width: 330px;
		text-align: center;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.news-image {
			height: 180px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			transition: all 0.3s;

			img {
				width: 100%;
				left: 0;
				transition: all 0.3s;
				position: relative;
			}
		}

		p {
			color: @color1;
			font-size: 11px;
			text-transform: uppercase;
			margin-top: 15px;
			font-weight: 400;
		}

		h2 {
			padding: 15px 10px;
			font-size: 22px;

			a {
				font-weight: 400;
				color: #000;
				text-decoration: none;
				transition: color 0.3s;
			}
		}

		.btn-link {
			text-transform: uppercase;
			font-size: 12px;
			text-decoration: none;
			color: #000;
		}

		&:hover {
			.news-image {
				box-shadow: 0px 4px 7px -3px rgba(0,0,0,0.14);

				img {
					width: 105%;
					left: -2.5%;
				}
			}

			h2 {
				a {
					color: @color1;
				}
			}
		}

		&:before {
			content: '';
			position: absolute;
			width: 330px;
			height: 180px;
			left: -5px;
			top: -5px;
			background: #000;
		}

		&.article-hidden {
			padding: 0;
			margin: 0;
			visibility: hidden;
		}
	}
}

.pagination {
	margin-bottom: 0;
	margin-top: 50px;

	li {
		a {
			background: #fff;
			border: none;
			color: @color1;
		}

		&.active, &:hover {
			a {
				background: @color1;
				border-color: @color1;
				color: #fff;
			}
		}

		&:first-child, &:last-child {
			a {
				border-radius: 0;
			}
		}
	}
}

div#section-header-contact {
	padding: 8px 0;
	background: @gray-dark;
	text-align: right;

	ul {
		li {
			a {
				img {
					max-height: 22px;
				}
			}
		}
	}
}

div#section-header {
	padding: 10px 0;

	nav.navbar {
		min-height: auto;
		border: none;
		border-radius: 0;
		background: none;
		margin-bottom: 0;

		.navbar-brand {
			height: auto;
			margin-top: 8px;
		}

		ul.navbar-nav {
			li {
				a {
					font-size: 18px;
					font-weight: 600;
					color: #222;
					padding: 30px 15px;

					img {
						width: 20px;
					}
				}
			}
		}

		.navbar-toggle {
			padding: 0;
			border-radius: 0;
			border: none;
			margin-top: 30px;

			.icon-bar {
				background-color: #222;
			}

			&:focus, &:hover {
				background: none;
			}
		}
	}
}

div#section-main-carousel {
	.owl-carousel-slider {
		.item {
			height: 700px !important;

			&.bg1 {
				background: url(../img/backgrounds/slider/1-OPPLA1_4.jpg) center center !important;
				background-size: cover !important;
				background-repeat: no-repeat !important;
			}
			
			&.bg2 {
				background: url(../img/backgrounds/slider/2-ASPEN3_4.jpg) center center !important;
				background-size: cover !important;
				background-repeat: no-repeat !important;
			}
			
			&.bg3 {
				background: url(../img/backgrounds/slider/3-INNALI_3.jpg) center center !important;
				background-size: cover !important;
				background-repeat: no-repeat !important;
			}
			
			&.bg4 {
				background: url(../img/backgrounds/slider/4-NORD_4.jpg) center center !important;
				background-size: cover !important;
				background-repeat: no-repeat !important;
			}
			
			&.bg5 {
				background: url(../img/backgrounds/slider/5-SILJE2_4.jpg) center center !important;
				background-size: cover !important;
				background-repeat: no-repeat !important;
			}

		}

		.owl-controls {
			display: none;
		}
	}
}

.produkty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-top: 80px;

	.h1 {
		font-size: 38px;
		text-transform: uppercase;
	}

	h1::after {
		content: '';
		display: block;
		height: 4px;
		width: 40px;
		background: #eee;
		margin: 10px auto;
	}

	.produkty-list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;
		gap: 30px 20px;
		margin-top: 15px;

		a {
			&:hover {
				text-decoration: none;
			}
		}

		.img-1 {
			background-image: url(../img/backgrounds/miniatury/oppla.jpg);
			background-repeat: no-repeat;

			.img-bg {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				width: 350px;
				height: 270px;
			}
			
			.img-p {
				text-transform: uppercase;
				font-size: 18px; 
				font-weight: 300;
				text-align: center;
				color: #000;
				padding-top: 10px;
			}
	   }
	   
	   .img-2 {
		   background-image: url(../img/backgrounds/miniatury/aspen.jpg);
		   background-repeat: no-repeat;

		   .img-bg {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			width: 350px;
			height: 270px;
		}
		
		.img-p {
			text-transform: uppercase;
			font-size: 18px; 
			font-weight: 300;
			text-align: center;
			color: #000;
			padding-top: 10px;
		}
	   }
	   
	   .img-3 {
		   background-image: url(../img/backgrounds/miniatury/inali.jpg);
		   background-repeat: no-repeat;

		   .img-bg {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			width: 350px;
			height: 270px;
		}
		
		.img-p {
			text-transform: uppercase;
			font-size: 18px; 
			font-weight: 300;
			text-align: center;
			color: #000;
			padding-top: 10px;
		}
	   }
	   
	   .img-4 {
		   background-image: url(../img/backgrounds/miniatury/nord.jpg);
		   background-repeat: no-repeat;
		   
			.img-bg {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				width: 350px;
				height: 270px;
			}
			
			.img-p {
				text-transform: uppercase;
				font-size: 18px; 
				font-weight: 300;
				text-align: center;
				color: #000;
				padding-top: 10px;
			}
	   }
	   
	   .img-5 {
		   background-image: url(../img/backgrounds/miniatury/silje.jpg);
		   background-repeat: no-repeat;
		   
			.img-bg {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				width: 350px;
				height: 270px;
			}
			
			.img-p {
				text-transform: uppercase;
				font-size: 18px; 
				font-weight: 300;
				text-align: center;
				color: #000;
				padding-top: 10px;
			}
	   }
	   
	   .img-6 {
		   background-image: url(../img/backgrounds/miniatury/umywalka-inalli.jpg);
		   background-repeat: no-repeat;
		   
			.img-bg {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				width: 350px;
				height: 270px;
			}
			
			.img-p {
				text-transform: uppercase;
				font-size: 18px; 
				font-weight: 300;
				text-align: center;
				color: #000;
				padding-top: 10px;
			}
	   }
	}
	

}

.section-flexbox {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.flexbox-description {
		max-width: 500px;

		h1 {
			font-size: 38px;
			text-transform: uppercase;

			&:after {
				content: '';
				display: block;
				height: 4px;
				width: 40px;
				background: @gray-lighter;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		p {
			font-weight: 200;
			font-size: 20px;
			line-height: 29px;
		}
	}
}

div#o-firmie {
	padding-top: 70px;

	.section-flexbox {
		&:first-child {
			margin-bottom: 50px;
			justify-content: center;

			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}
	}

	img {
		max-width: 100%;
	}
}

div#gwarancja {
	padding-top: 70px;

	.section-flexbox {
		justify-content: center;
		text-align: center;

		.flexbox-description {
			max-width: 700px;

			img {
				width: 80px;
				margin-bottom: 20px;
			}
		}

		&:first-child {
			margin-bottom: 50px;

			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}
	}

	.warranty-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 50px;

		img {
			max-height: 250px;
		}

		.flexbox-description {		
			margin-left: 30px;

			p {
				font-weight: 200;
				font-size: 20px;
				line-height: 29px;
			}

			.btn {
				margin-top: 20px;
			}
		}
	}

	img {
		max-width: 100%;
	}
}

div#technologie {
	padding: 100px 0 70px 0;

	.section-flexbox {
		justify-content: center;
		margin-bottom: 50px;

		.flexbox-description {
			max-width: 650px;

			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}
	}

	.step-service {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.service-description {
			width: 40%;

			h2 {
				font-size: 38px;
				font-weight: 200;
			}

			p {
				margin-top: 15px;
				font-size: 20px;
				font-weight: 200;
			}
		}

		.service-image {
			width: 60%;
			padding: 20px 50px;
		}
	}
}

div#produkty {
    padding: 70px 0;
    background-color: #ededed !important;
    background-image: url(../img/backgrounds/produkty-bg4.jpg) !important;
    background-position: left top !important;
    background-repeat: no-repeat !important;
    background-size: contain;

	.btn {
		background: @gray-dark;
		color: #fff;
	}

	.custom-search-input {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.input-group {
			width: 400px;
			max-width: 100%;
			margin-right: 50px;

			input {
				border-radius: 0;
				border: none;
				border-right: none;
				height: 34px;
				box-shadow: none;
				background: rgba(255, 255, 255, 1);
			}

			::-webkit-input-placeholder { color:@gray-light; text-transform:uppercase; font-size:12px; }
			::-moz-placeholder { color:@gray-light; text-transform:uppercase; font-size:12px; }
			:-ms-input-placeholder { color:@gray-light; text-transform:uppercase; font-size:12px; }
			input:-moz-placeholder { color:@gray-light; text-transform:uppercase; font-size:12px; }

			button {
				background: @gray-dark;
				height: 34px;
				width: 46px;
				border-radius: 0;
				border: none;

				img {
					max-height: 17px;
				}
			}
		}
		
		.btn-default {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.select-wrapper {
		position: relative;
		margin-top: 5px;

		select {
			background: none;
			border: none;
			border-bottom: 1px solid @gray;
			font-size: 15px;
			color: #6d6d6d;
			appearance: none;
			padding: 0;
			outline: none;
			position: relative;
			z-index: 111;
			margin-right: 20px;

			option {
				color: #333;
			}
		}

		&:before {
			content: '';
			background: url(../img/pictures/home-icon.png);
			width: 18px;
			height: 19px;
			position: absolute;
			left: 5px;
			top: 0;
		}

		&:after {
			content: '';
			background: url(../img/pictures/chevron-icon-dark.png);
			width: 16px;
			height: 9px;
			position: absolute;
			right: 10px;
			top: 7px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.section-flexbox {
		justify-content: center;

		.flexbox-description {
			color: #fff;
			max-width: 100% !important;

			h1 {

				font-size: 38px;
				text-transform: uppercase;
				color: #000;

				&:after {
					background: @gray;
					margin: 10px auto;
				}
			}

			p {
				font-size: 14px;
				color: #fff;
			}
		}
	}

	ul {
		margin-bottom: 30px;

		li {
			.btn {
				background: #222;
				border-color: #222;
				color: #fff;
			}

			&.active {
				.btn {
					background: #555;
					border-color: #555;

				}
			}
		}
	}

	.flexbox-products {
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;

		a {
			width: calc(100% / 3);
			display: block;
			text-align: center;
			text-decoration: none;
			color: #999;
			text-transform: uppercase;
			position: relative;

			img {
				width: 100%;
				transition: all 0.3s;
				border: none !important;
			}

			p {
				font-size: 18px;
				color: #000 !important;
			}

			.service-cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				text-align: center;
				opacity: 0;
				transition: all 0.3s;

				p {
					color: #fff;
					font-size: 17px;
					text-shadow: none !important;
					text-transform: none;
				}
			}

			&:hover {
				img {
					opacity: 0.4;
					border: 1px solid #111;
				}

				.service-cover {
					opacity: 1;
				}
			}
		}
	}

	.single-product {
		margin-top: 50px;

		.btn {
			font-size: 16px;
			margin-top: 30px;
		}
	}
}

div#produkt {
	.produkt-wrapper {
		position: relative;
		padding-bottom: 50px;

		.wrapper-background {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 200px;
			background: #000;
		}

		.heading-flexbox {
			display: flex;
			align-items: center;

			.image-wrapper {
				min-width: 600px;
				max-width: 600px;

				.welcome-absolute-image {
					position: relative;
					height: 330px;

					img {
						// position: absolute;
						// max-height: 330px;
						max-width: 100%;
						// top: 50px;
						// left: 0;
					}
				}
				
				.image-gallery {
					position: relative;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding-left: 30px;
					margin-top: 50px;

					a {
						margin-top: 20px;
						width: 23%;

						img {
							width: 100%;
						}

						&.image-hidden {
							margin: 0;
							visibility: hidden;
						}
					}
				}
			}

			.welcome-description {
				padding-left: 50px;

				h1 {
					text-transform: uppercase;
					font-size: 40px;
					font-weight: 400;
					color: @color1;
				}

				.description {
					margin-top: 20px;
					margin-bottom: 20px;

					p {
						margin-bottom: 10px;
						line-height: 24px;
						font-size: 14px;
						color: #000;

						strong {
							color: @color1;
						}
					}
				}
			}
		}

		.content-icons {
			border-top: 1px solid @color1;
			margin-top: 50px;
			padding: 30px 120px 0 120px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			a {
				width: 7%;
				padding: 5px;

				img {
					width: 100%;
				}
			}
		}

		.section-flexbox {
			margin-top: 50px;

			.flexbox-description {
				width: 100%;
				max-width: 100%;

				h1 {
					&:after {
						margin: 10px auto;
					}
				}
			}
		}

		.content-arrangements {
			padding: 0 120px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			
			a {
				margin-top: 20px;
				width: 22%;

				img {
					width: 100%;
				}

				&.image-hidden {
					margin: 0;
					visibility: hidden;
				}
			}
		}
	}
}

div#ikony {
	padding-bottom: 30px;
	background-color: #000;

	.icons-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.icon-service {
			text-align: center;
			margin-top: 30px;
			width: 20%;

			img {
				height: 50px;
			}

			p {
				margin-top: 10px;
				font-size: 16px;
				color: #999;
				padding: 0 10px;
			}

			&.small-icon {
				img {
					height: 35px;
				}
			}

			&.hidden-icon {
				visibility: hidden;
			}
		}
	}
}

div#section-news {
	padding: 40px 0;

	.section-flexbox {
		justify-content: center;

		.flexbox-description {
			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}
	}
}

div#aktualnosc-section-content {
	padding: 40px 0;

	article {
		text-align: center;

		img {
			width: 400px;
			max-width: 100%;
		}

		h1 {
			margin-top: 30px;
		}

		p.date {
			margin-top: 15px;
			color: @color1;
		}

		section {
			p {
				line-height: 26px;
			}
		}
	}
}

div#dystrybutorzy-section-content {
	padding: 40px 0;

	.section-flexbox {
		justify-content: center;

		.flexbox-description {
			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}
	}

	.panel-group {
		margin-top: 30px;
		margin-bottom: 0;

		.panel {
			border: none;
			background: none;
			box-shadow: none;
			margin-top: 10px;

			.panel-heading {
				background: none;
				padding: 10px;
				border-radius: 0;
				border: 1px solid @color1;

				h4 {
					font-size: 18px;
					font-weight: 700;

					a {
						text-decoration: none;

						span {
							font-size: 12px;
							margin-left: 5px;
							top: 0;
						}
					}
				}

				&.actived {
					background: @color1;
					color: #fff;
				}
			}

			.panel-body {
				border: none;
				padding: 0;

				table {
					margin-bottom: 0;

					tr {
						td {
							border-top: none;

							p {
								font-size: 16px;
								font-weight: 400;
							}

							a {
								font-size: 16px;
								color: @color1;
								font-weight: 700;
							}

							&:before {
								display: block;
								text-transform: uppercase;
								font-size: 11px;
								color: @gray-light;
							}

							&:nth-child(1) {
								&:before {
									content: 'Nazwa';
								}
							}

							&:nth-child(2) {
								&:before {
									content: 'Ulica';
								}
							}

							&:nth-child(3) {
								&:before {
									content: 'Miasto';
								}
							}

							&:nth-child(4) {
								text-align: right;

								&:before {
									content: 'Telefon';
								}
							}
						}

						&:nth-child(2n) {
							background: @gray-lighter;
						}
					}
				}
			}
		}
	}
}

div#gwarancja-satysfakcji-section-content {
	padding-bottom: 50px;
	text-align: center;

	.content-heading {
		height: 300px;
		background: url(../img/backgrounds/fotolia_125982734.jpg) center;
		background-size: cover;
		margin-bottom: 50px;
	}

	h1 {
		font-size: 38px;
		text-transform: uppercase;

		&:after {
			content: '';
			display: block;
			height: 4px;
			width: 40px;
			background: @gray-lighter;
			margin: 10px auto;
		}
	}

	p {
		margin-top: 20px;
		font-weight: 200;
		font-size: 20px;
		line-height: 29px;
	}

	.btn {
		margin-top: 30px;
	}
}

div#kontakt {
	padding-top: 70px;
	background: #000;
	text-align: center;

	ul.footer-social {
		li {
			a {
				img {
					max-height: 30px;
				}
			}
		}
	}

	.footer-description {
		margin-top: 20px;

		a {
			color: #555;
			font-size: 18px;
			text-decoration: none;
		}

		p {
			color: #777;
			font-size: 25px;
		}
	}

	.alert {
		background: none;
		border: 1px solid #444;
		margin-bottom: 0;
		margin-top: 50px;
		text-transform: uppercase;
		border-radius: 0;
		padding: 20px;

		p {
			font-size: 11px;
			color: #555;
		}

		a {
			color: #999;
			text-decoration: none;
		}
	}

	.footer-credits {
		margin-top: 30px;
		padding-bottom: 20px;
		background: #000;
		text-align: left;

		p, a {
			color: #777;
			font-size: 13px;
			text-decoration: none;
		}
	}
}

@media (max-width: @screen-md-max) {
	div#section-main-carousel {
		.owl-carousel-slider {
			.item {
				h1 {
					max-width: 940px;
				}

				&.bg2 {
					.description-wrapper {
						max-width: 940px;
					}
				}
			}
		}
	}
}

@media (max-width: @screen-sm-max) {
	div#section-main-carousel {
		.owl-carousel-slider {
			.item {
				h1 {
					width: 720px;
				}

				&.bg1 {
					h1 {
						padding-top: 220px;
						padding-left: 0px;
						text-align: center;
					}

					&.second {
						h1 {
							padding-top: 50px;
						}
					}
				}
				
				&.bg2 {
					h1 {
						padding-top: 220px;
						padding-left: 0px;
						text-align: center;
					}

					.description-wrapper {
						display: none;
					}
				}
			}

			.owl-controls {
				display: none;
			}
		}
	}

	.section-flexbox {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		.flexbox-description {
			max-width: 600px;

			h1 {
				&:after {
					margin: 10px auto;
				}
			}
		}

		img {
			margin-top: 30px;
		}
	}

	div#technologie {
		.step-service {
			margin-top: 50px;
		}
	}

	div#produkty {
		.flexbox-products {
			a {
				width: calc(100% / 3);
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	div#section-header {
		padding: 10px 0;

		nav.navbar {
			.navbar-collapse {
				border: none;
				box-shadow: none;

				ul.navbar-nav {
					li {
						a {
							padding: 15px 15px;
						}
					}
				}
			}
		}
	}

	div#section-main-carousel {
		.owl-carousel-slider {
			.item {
				h1 {
					width: auto;
					text-align: center !important;
				}
			}
		}
	}

	div#gwarancja {
		.warranty-flexbox {
			flex-direction: column-reverse;
			text-align: center;

			img {
				max-height: 250px;
				margin-top: 30px;
			}

			p {
				margin: 0;
			}
		}
	}

	div#technologie {
		.step-service {
			flex-direction: column;
			justify-content: center;
			text-align: center;

			.service-description {
				width: 100%;
			}

			.service-image {
				width: 100%;
			}

			&:nth-child(2), &:nth-child(4) {
				flex-direction: column-reverse;
			}
		}
	}

	div#produkty {
		.flexbox-products {
			a {
				width: calc(100% / 2);
			}
		}

		.custom-search-input {
			align-items: center;
			flex-direction: column;

			.input-group {
				margin-right: 0;
			}

			.select-wrapper {
				margin-top: 15px;

				select {
					margin-right: 0;
				}
			}

			.btn-default {
				margin-top: 15px;
			}
		}
	}

	div#ikony {
		.icons-flexbox {
			.icon-service {
				width: calc(100% / 3);

				&.hidden-icon {
					display: none;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-min) {
	div#produkty {
		.flexbox-products {
			a {
				width: 100%;
			}
		}
	}

	div#ikony {
		.icons-flexbox {
			.icon-service {
				width: calc(100% / 2);
			}
		}
	}
}

@media (max-width: 380px) {
	div#ikony {
		.icons-flexbox {
			.icon-service {
				width: 100%;
			}
		}
	}
}